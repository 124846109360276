
$(document).ready(function() {
    var cover = $('.fotoAlbum');
    if(cover != null) {
        var image = $(cover).children('img')[0];
        var height = $(image).height();
        var width = $(image).width();
        $(cover).height($(cover).width());
        $(image).height($(cover).width());
        if (height < width) {
            $(image).css('width', $(cover).width());
            $(image).css('height', "auto");
        } else {
            $(image).css('height', $(cover).width());
            $(image).css('width', "auto");
        }
    }

    /*var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
    if(height <= 800) {
        $('footer').addClass('footerBottom');
    }*/

    $(window).on('resize', function(){
        var body_height = $('footer').hasClass('footerBottom') ? $('body').height() + 200 : $('body').height();
        if (body_height < $(window).height())
            $('footer').addClass('footerBottom');
        else
            $('footer').removeClass('footerBottom');
    });

	$('.btMenu').on('click', function() {
        $("nav").slideToggle(); 
	});
	$('.btNosotros').on('click', function() {
        $(".nosotros").fadeIn("slow");
         $(".banner1 .banner").fadeOut("slow"); 
         scrollTo(0, 0);

	});
	$('.nosotros .cerrar').on('click', function() {
        $(".nosotros").fadeOut("slow"); 
         $(".banner1 .banner").fadeIn("slow"); 
	});
    
   $('.ancla').click(function(e){
       e.preventDefault();
       enlace  = $(this).attr('href');
       $('html, body').animate({
            scrollTop: $(enlace).offset().top 
       }, 300);
	});
	$('.menu').on('click', function() {
        $(".subMenu").slideToggle(); 
		$(".cuadro").toggleClass("abierto")
	});

    $("#sticker").sticky({ topSpacing: 0 });
    $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
            $("header").addClass("scroll");
        } else {
            $("header").removeClass("scroll");
        }
    });


    $(document).on('keypress', ".numbersOnly", function(event) {
        return /\d/.test(String.fromCharCode(event.keyCode));
    });

    /**
     * Textara Maxlength Validation
     */
    $('textarea[maxlength]').keyup(function(){  
        //get the limit from maxlength attribute  
        var limit = parseInt($(this).attr('maxlength'));  
        //get the current text inside the textarea  
        var text = $(this).val();  
        //count the number of characters in the text  
        var chars = text.length;  
  
        //check if there are more characters then allowed  
        if(chars > limit){  
            //and if there are use substr to get the text before the limit  
            var new_text = text.substr(0, limit);  
  
            //and change the current text with the new text  
            $(this).val(new_text);  
        }  
    }); 

});



//
// $('#element').donetyping(callback[, timeout=1000])
// Fires callback when a user has finished typing. This is determined by the time elapsed
// since the last keystroke and timeout parameter or the blur event--whichever comes first.
//   @callback: function to be called when even triggers
//   @timeout:  (default=1000) timeout, in ms, to to wait before triggering event if not
//              caused by blur.
// Requires jQuery 1.7+
//
;(function($){
    $.fn.extend({
        donetyping: function(callback,timeout){
            timeout = timeout || 1e3; // 1 second default timeout
            var timeoutReference,
                doneTyping = function(el){
                    if (!timeoutReference) return;
                    timeoutReference = null;
                    callback.call(el);
                };
            return this.each(function(i,el){
                var $el = $(el);
                // Chrome Fix (Use keyup over keypress to detect backspace)
                // thank you @palerdot
                $el.is(':input') && $el.on('keyup keypress paste',function(e){
                    // This catches the backspace button in chrome, but also prevents
                    // the event from triggering too preemptively. Without this line,
                    // using tab/shift+tab will make the focused element fire the callback.
                    if (e.type=='keyup' && e.keyCode!=8) return;
                    
                    // Check if timeout has been set. If it has, "reset" the clock and
                    // start over again.
                    if (timeoutReference) clearTimeout(timeoutReference);
                    timeoutReference = setTimeout(function(){
                        // if we made it here, our timeout has elapsed. Fire the
                        // callback
                        doneTyping(el);
                    }, timeout);
                }).on('blur',function(){
                    // If we can, fire the event since we're leaving the field
                    doneTyping(el);
                });
            });
        }
    });
})(jQuery);

$(document).ready(function() {
(function() {
	var container = document.querySelector( 'section.container' ),
		triggerBttn = document.getElementById( 'trigger-overlay' ),
		overlay = document.querySelector( 'div.overlay' ),
        triggerBtnClass = $('.trigger-overlay');
    if(overlay == null )
        return;
		var closeBttn = overlay.querySelector( 'button.overlay-close' );
		transEndEventNames = {
			'WebkitTransition': 'webkitTransitionEnd',
			'MozTransition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'msTransition': 'MSTransitionEnd',
			'transition': 'transitionend'
		},
		transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
		support = { transitions : Modernizr.csstransitions };

	function toggleOverlay() {
		if( classie.has( overlay, 'open' ) ) {
			classie.remove( overlay, 'open' );
			classie.remove( container, 'overlay-open' );
			classie.add( overlay, 'close' );
			var onEndTransitionFn = function( ev ) {
				if( support.transitions ) {
					if( ev.propertyName !== 'visibility' ) return;
					this.removeEventListener( transEndEventName, onEndTransitionFn );
				}
				classie.remove( overlay, 'close' );
			};
			if( support.transitions ) {
				overlay.addEventListener( transEndEventName, onEndTransitionFn );
			}
			else {
				onEndTransitionFn();
			}
		}
		else if( !classie.has( overlay, 'close' ) ) {
			classie.add( overlay, 'open' );
			classie.add( container, 'overlay-open' );
		}
	}

    if (triggerBttn)
	    triggerBttn.addEventListener( 'click', toggleOverlay );
    $('.trigger-overlay').bind('click', function(evt) {
        evt.preventDefault();
        toggleOverlay();
    });
	closeBttn.addEventListener( 'click', toggleOverlay );
    /*!
 * classie - class helper functions
 * from bonzo https://github.com/ded/bonzo
 * 
 * classie.has( elem, 'my-class' ) -> true/false
 * classie.add( elem, 'my-new-class' )
 * classie.remove( elem, 'my-unwanted-class' )
 * classie.toggle( elem, 'my-class' )
 */

/*jshint browser: true, strict: true, undef: true */
/*global define: false */

( function( window ) {

'use strict';

// class helper functions from bonzo https://github.com/ded/bonzo

function classReg( className ) {
  return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
}

// classList support for class management
// altho to be fair, the api sucks because it won't accept multiple classes at once
var hasClass, addClass, removeClass;

if ( 'classList' in document.documentElement ) {
  hasClass = function( elem, c ) {
    return elem.classList.contains( c );
  };
  addClass = function( elem, c ) {
    elem.classList.add( c );
  };
  removeClass = function( elem, c ) {
    elem.classList.remove( c );
  };
}
else {
  hasClass = function( elem, c ) {
    return classReg( c ).test( elem.className );
  };
  addClass = function( elem, c ) {
    if ( !hasClass( elem, c ) ) {
      elem.className = elem.className + ' ' + c;
    }
  };
  removeClass = function( elem, c ) {
    elem.className = elem.className.replace( classReg( c ), ' ' );
  };
}

function toggleClass( elem, c ) {
  var fn = hasClass( elem, c ) ? removeClass : addClass;
  fn( elem, c );
}

var classie = {
  // full names
  hasClass: hasClass,
  addClass: addClass,
  removeClass: removeClass,
  toggleClass: toggleClass,
  // short names
  has: hasClass,
  add: addClass,
  remove: removeClass,
  toggle: toggleClass
};

// transport
if ( typeof define === 'function' && define.amd ) {
  // AMD
  define( classie );
} else {
  // browser global
  window.classie = classie;
}

})( window );

})();
    });